<template>
  <div>
    <div class="flex">
      <!-- navbar area -->
      <div
        class="w-full fixed top-0 bg-white border-b shadow-md py-3 px-6 z-20 flex items-center gap-16 h-14 justify-between lg:hidden"
      >
        <div class="flex gap-3 items-center">
          <button @click="showSidebar = !showSidebar">
            <img
              src="/img/icons/hamburger.svg"
              class="w-5 h-5"
              alt="toggle sidebar"
            />
          </button>
          <img src="/img/logo-2.svg" class="h-4 mx-auto" alt="RTku App" />
        </div>
        <!-- use custom activeClass to prevent styled with router-link-active class style  -->
        <div class="flex gap-4">
          <button @click="openReportModal">
            <img src="/img/icons/report.svg" class="w-10 h-10" alt="" />
          </button>
          <router-link
            class="no-underline text-inherit max-w-[40%] sm:max-w-[35%] truncate"
            to="/profil"
            activeClass="profile-link-active"
          >
            <img
              :src="avatar"
              class="h-10 w-10 object-cover rounded-full border-2 border-grey"
              alt="user"
            />
          </router-link>
        </div>
      </div>
      <!-- sidebar area -->
      <div
        class="h-screen fixed w-60 top-14 px-3 py-5 border-r border-grey -translate-x-full transition-transform duration-300 bg-white z-20 md:py-10 md:pr-8 md:pl-10 lg:top-0 lg:translate-x-0"
        :class="{ 'translate-x-0': showSidebar }"
      >
        <img
          src="/img/logo.svg"
          class="h-7 mx-auto mb-16 hidden lg:block"
          alt="RTku App"
        />
        <div v-for="link in link_menu" :key="link.name">
          <router-link
            v-if="link.role === 'ALL' || link.role === user?.type"
            :to="link.path"
            class="flex gap-3 items-center p-3 mb-6"
            @click="showSidebar = false"
          >
            <img :src="link.image" class="w-5 h-5" :alt="link.icon" />
            <p class="text-sm font-medium text-g90">{{ link.name }}</p>
          </router-link>
        </div>
        <button class="flex gap-3 items-center p-3" @click="confirmLogout">
          <img src="/img/icons/logout.svg" class="w-5 h-5" alt="Logout" />
          <p class="text-sm font-medium text-g90">Keluar</p>
        </button>
      </div>
      <!-- content area -->
      <div
        class="content_area px-2 w-full ms-auto md:px-8 lg:px-10 md:pt-8 xl:px-12"
      >
        <div class="mb-12 justify-between items-start hidden lg:flex">
          <div v-if="path == '/beranda'" class="w-full">
            <h1 class="text-2xl mb-1 font-bold">Selamat Datang!</h1>
            <p class="text-g90">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            </p>
          </div>
          <div v-else>
            <h1 class="text-2xl font-bold whitespace-nowrap">
              {{ route?.name }}
            </h1>
          </div>
          <div
            class="flex gap-6 2xl:gap-8 items-center justify-end max-xl:w-4/5"
            :class="path != '/beranda' ? 'ml-auto' : ''"
          >
            <button @click="openReportModal">
              <img src="/img/icons/report.svg" class="w-12 h-12" alt="" />
            </button>
            <!-- <button>
              <img src="/img/icons/mail.svg" alt="" />
            </button>
            <button>
              <img src="/img/icons/bell.svg" alt="" />
            </button> -->
            <!-- use custom activeClass to prevent styled with router-link-active class style  -->
            <router-link
              class="no-underline text-inherit"
              to="/profil"
              activeClass="profile-link-active"
            >
              <div class="flex gap-3">
                <img
                  :src="avatar"
                  class="h-10 w-10 object-cover rounded-full border-2 border-grey"
                  alt=""
                />
                <div>
                  <p class="font-bold">{{ $name(user?.name) }}</p>
                  <p class="text-sm text-g90">{{ user?.classification }}</p>
                </div>
              </div>
            </router-link>
          </div>
        </div>
        <div class="relative" @click="showSidebar = false">
          <router-view class="max-md:mt-14 max-lg:p-5"></router-view>
          <div
            class="w-full h-full bg-black/50 fixed top-0 left-0 lg:hidden"
            v-show="showSidebar"
          ></div>
        </div>
      </div>
      <!-- Modal -->
      <div
        class="flex fixed left-0 top-0 w-full h-full justify-center items-center z-30 bg-black bg-opacity-50"
        :class="{ hidden: !showReportModal }"
      >
        <div class="bg-white p-5 w-[90%] md:w-4/5 lg:w-1/2 rounded-xl">
          <div class="flex justify-between items-center mb-8">
            <h2 class="text-lg font-bold">Buat Laporan</h2>
            <span class="text-2xl cursor-pointer" @click="closeReportModal"
              >&times;</span
            >
          </div>
          <form @submit.prevent="reportSubmit">
            <div class="mb-5">
              <label for="name" class="label_form">Nama</label>
              <input
                type="text"
                id="name"
                name="name"
                placeholder="Ketik nama Anda..."
                v-model="report.name"
                class="input_form border-[#cdcdcd]"
                required
              />
            </div>
            <div class="mb-5">
              <label for="report" class="label_form">Deskripsi Laporan</label>
              <textarea
                type="text"
                id="report"
                name="report"
                v-model="report.report"
                placeholder="Ketik laporan Anda..."
                rows="5"
                class="input_form border-[#cdcdcd]"
                required
              ></textarea>
            </div>
            <div class="mb-5">
              <label for="report_category_id" class="label_form"
                >Kategori</label
              >
              <select
                id="report_category_id"
                name="report_category_id"
                v-model="report.report_category_id"
                class="input_form border-[#cdcdcd]"
                required
              >
                <option
                  v-for="(value, key) in report_category"
                  :key="key"
                  :value="value.id"
                >
                  {{ value.name }}
                </option>
              </select>
            </div>
            <div class="mb-5">
              <label for="image" class="label_form"
                >Upload Bukti (Opsional)</label
              >
              <label
                for="image"
                class="bg-[#FAFCFE] flex justify-center items-center flex-col p-4 rounded-md border-dashed border border-[#cdcdcd] mb-4 cursor-pointer"
              >
                <img src="/img/icons/upload.svg" width="80" alt="" />
                <p class="text-g90 text-sm mt-2">Upload Berkas</p>
              </label>
              <div
                v-if="report.image"
                class="flex justify-between items-center"
              >
                <p class="text-g90 text-sm">{{ image_name }}</p>
                <button @click.prevent="report.image = null">&times;</button>
              </div>
              <input
                type="file"
                class="hidden"
                id="image"
                name="image"
                @change="(e) => onChangeFile(e.target.files[0])"
                accept="image/*"
              />
            </div>
            <div class="flex justify-end">
              <button
                class="w-32 h-12 rounded-md bg-primary text-white text-sm font-bold"
                type="submit"
              >
                <CircleLoader v-if="loadReport === true" />
                <span v-else> Kirim Laporan </span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch, onMounted } from 'vue';
import { useAuthStore } from '@/store/authentication';
import { useRouter, useRoute } from 'vue-router';
import Swal from 'sweetalert2';
import axios from 'axios';
import CircleLoader from '@/components/CircleLoader';

const props = defineProps({
  title: String,
});
const loadReport = ref(false);
const report = ref({
  name: '',
  report: '',
  report_category_id: '',
  image: null,
});
const report_category = ref({});
const image_name = ref('');
// report category
const getReportCategory = async () => {
  const { data } = await axios.get('report_category');
  report_category.value = data.data;
};

// submit report
const reportSubmit = async () => {
  loadReport.value = true;
  try {
    if (report.value.image && !(report.value.image instanceof File)) {
      console.error('Invalid image format. Please select an image.');
      return;
    }

    const formData = new FormData();
    formData.append('name', report.value.name);
    formData.append('report', report.value.report);
    formData.append('report_category_id', report.value.report_category_id);
    formData.append('image', report.value.image);

    const response = await axios.post('/report', formData);

    if (response.status == 200) {
      Swal.fire({
        position: 'center',
        icon: 'success',
        text: response.data.message,
        showConfirmButton: false,
        timer: 1500,
      });
      report.value = {
        name: '',
        report: '',
        report_category_id: '',
        image: null,
      };
      closeReportModal();
    }
    loadReport.value = false;
  } catch (error) {
    loadReport.value = false;
    console.error('Error submitting report:', error);
  }
};

const onChangeFile = (file) => {
  report.value.image = file;
  image_name.value = file.name;
};

const showReportModal = ref(false);
const auth = useAuthStore();
const user = computed(() => auth.authUser);
const avatar = computed(() => auth.avatar);
const router = useRouter();
const showSidebar = ref(false);
const path = ref('');
const route = useRoute();

const openReportModal = () => {
  showReportModal.value = true;
};

const closeReportModal = () => {
  showReportModal.value = false;
};

const link_menu = [
  {
    name: 'Beranda',
    icon: 'home',
    path: '/beranda',
    image: '/img/icons/home.svg',
    role: 'ALL',
  },
  {
    name: 'Tagihan',
    icon: 'tagihan',
    path: '/tagihan',
    image: '/img/icons/tagihan.svg',
    role: 'KEPALA_KELUARGA',
  },
  {
    name: 'Daftar Warga',
    icon: 'daftar_warga',
    path: '/daftar-warga',
    image: '/img/icons/daftar_warga.svg',
    role: 'ALL',
  },
  {
    name: 'Profil',
    icon: 'profile',
    path: '/profil',
    image: '/img/icons/profile.svg',
    role: 'ALL',
  },
];

// Fungsi konfirmasi logout
const confirmLogout = () => {
  Swal.fire({
    title: 'Keluar?',
    text: 'Apakah anda yakin keluar? Gunakan nomor HP untuk masuk kembali',
    icon: 'warning',
    confirmButtonText: 'Keluar',
  }).then((result) => {
    if (result.isConfirmed) {
      // Jika pengguna menekan "Keluar," logout akan dilakukan
      performLogout();
    }
  });
};

// Fungsi untuk melakukan logout
const performLogout = () => {
  auth.logout().then(() => router.push('/'));
};

watch(
  () => route.path,
  () => (path.value = route.path),
  {
    immediate: true,
  }
);

onMounted(() => {
  if (user.isAuth) getReportCategory();
});
</script>

<style scoped lang="scss">
a.router-link-active {
  @apply bg-p20 rounded-lg;

  p {
    @apply text-primary;
  }

  img {
    filter: invert(30%) sepia(81%) saturate(4279%) hue-rotate(218deg)
      brightness(100%) contrast(108%);
  }
}

@media (min-width: 1024px) {
  .content_area {
    width: calc(100% - 15rem);
  }
}
</style>
