import axios from 'axios';
import { setHeaderToken, removeHeaderToken } from '@/includes/auth';
import { defineStore } from 'pinia';

const localUser = localStorage.getItem('user');

export const useAuthStore = defineStore({
  id: 'auth',
  state: () => ({
    user: {},
    token: '',
    phone: '',
    coderesult: '',
  }),
  getters: {
    isAuth: (state) => {
      return !!state.token;
    },
    authUser: (state) => {
      return state?.user || null;
    },
    avatar: (state) => {
      return state.user?.avatar
        ? process.env.VUE_APP_URL + state.user?.avatar
        : '/img/profile.png';
    },
  },
  actions: {
    setPhone(payload) {
      this.phone = payload;
    },
    getUser() {
      this.user = JSON.parse(localStorage.getItem('user'));
      this.token = localStorage.getItem('token');
    },
    loginPassword(payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('/login_phone_password', payload)
          .then(({ data }) => {
            const token = data.data.token;
            this.user = data.data.user;
            this.token = token;
            localStorage.setItem('token', token);
            localStorage.setItem('user', JSON.stringify(this.user));
            setHeaderToken(token);
            resolve(data);
          })
          .catch((error) => {
            localStorage.removeItem('token');
            this.user = null;
            this.isLogined = false;
            reject(error);
          });
      });
    },
    login(payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('/login', payload)
          .then(({ data }) => {
            const token = data.data.token;
            this.user = data.data.user;
            this.token = token;
            localStorage.setItem('token', token);
            localStorage.setItem('user', JSON.stringify(this.user));
            setHeaderToken(token);
            resolve(data);
          })
          .catch((error) => {
            localStorage.removeItem('token');
            this.user = null;
            this.isLogined = false;
            reject(error);
          });
      });
    },
    async logout() {
      await axios.post('/logout');
      this.user = null;
      this.token = null;
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      removeHeaderToken();
    },
    async changeAvatar(formdata) {
      try {
        const { data } = await axios.post(
          `change_avatar/${this.user.id}`,
          formdata
        );
        this.user = data.data;
        localStorage.setItem('user', JSON.stringify(this.user));
      } catch (error) {
        console.log(error);
      }
    },
    async editProfile(payload) {
      try {
        const { data } = await axios.post('update_profile', payload);
        this.user = data.data;
        localStorage.setItem('user', JSON.stringify(this.user));
      } catch (error) {
        console.log(error);
      }
    },
  },
});
