<template>
  <RouterView v-if="isNoneSidebar" />
  <Sidebar v-else />
</template>

<script setup>
import Sidebar from "./components/Sidebar.vue";
import { computed } from "vue";
import { useRoute, useRouter } from "vue-router";

const route = useRoute();
const isNoneSidebar = computed(() => ["Login", "Detail Informasi", "Informasi", "LoginNumber"].includes(route.name));
const router = useRouter();
const redirectToLoginNumber = () => {
  router.push({ name: 'login-number' });
};
</script>
