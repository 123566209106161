import { useAuthStore } from '@/store/authentication';
import { createRouter, createWebHistory } from 'vue-router';
import LoginPassword from '@/components/LoginPassword.vue';
import LoginNumber from '@/components/LoginNumber.vue';

const routes = [
  {
    path: '/',
    name: 'Login',
    component: () => import('../views/Login.vue'),
    meta: {
      auth: false,
      title: 'Login',
    },
  },
  {
    path: '/login-number',
    name: 'LoginNumber',
    component: () => import('../components/LoginNumber.vue'),
  },
  {
    path: '/beranda',
    name: 'Home',
    component: () => import('../views/Home.vue'),
    meta: {
      auth: true,
      title: 'Home',
    },
  },
  {
    path: '/tagihan',
    name: 'Tagihan',
    component: () => import('../views/Tagihan.vue'),
    meta: {
      auth: true,
      title: 'Tagihan',
    },
  },
  {
    path: '/rincian-saldo',
    name: 'Rincian Saldo',
    component: () => import('../views/Rincian.vue'),
    meta: {
      auth: true,
    },
  },
  {
    path: '/profil',
    name: 'Profile',
    component: () => import('../views/Profile.vue'),
    meta: {
      auth: true,
      title: 'Profil Saya',
    },
  },
  {
    path: '/detail-tagihan/:id',
    name: 'Detail Tagihan',
    component: () => import('../views/DetailTagihan.vue'),
    meta: {
      auth: true,
      title: 'Detail Tagihan',
    },
  },
  {
    path: '/informasi',
    name: 'Informasi',
    component: () => import('../views/Informasi.vue'),
    meta: {
      auth: true,
    },
  },
  {
    path: '/detail-informasi/:id',
    name: 'Detail Informasi',
    component: () => import('../views/DetailInformasi.vue'),
    meta: {
      auth: true,
      title: 'Detail Informasi',
    },
  },
  {
    path: '/edit-profil',
    name: 'Edit Profil',
    component: () => import('../views/EditProfile.vue'),
    meta: {
      auth: true,
      title: 'Edit Profil',
    },
  },
  {
    path: '/edit-password',
    name: 'Edit Password',
    component: () => import('../views/EditPassword.vue'),
    meta: {
      auth: true,
      title: 'Edit Password',
    },
  },
  {
    path: '/edit-data-keluarga/:id',
    name: 'Edit Data Keluarga',
    component: () => import('../views/EditDataKeluarga.vue'),
    meta: {
      auth: true,
      title: 'Edit Data Keluarga',
    },
  },
  {
    path: '/detail-warga',
    name: 'Detail Warga',
    component: () => import('../views/DetailWarga.vue'),
    meta: {
      auth: true,
      title: 'Detail Warga',
    },
  },
  {
    path: '/daftar-warga',
    name: 'Daftar Warga',
    component: () => import('../views/DaftarWarga.vue'),
    meta: {
      auth: true,
      title: 'Daftar Warga',
    },
  },
  {
    path: '/informasi',
    name: 'Informasi',
    component: () => import('../views/Informasi.vue'),
    meta: {
      auth: true,
      title: 'Informasi',
    },
  },
  {
    path: '/daftar-anggota-keluarga',
    name: 'Daftar Anggota Keluarga',
    component: () => import('../views/DaftarAnggotaKeluarga.vue'),
    meta: {
      auth: true,
      title: 'Daftar Anggota Keluarga',
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const auth = useAuthStore();
  if (to.matched.some((record) => record.meta.auth && !auth.isAuth))
    next({ name: 'Login' });
  else if (to.matched.some((record) => !record.meta.auth && auth.isAuth))
    next({ name: 'Home' });
  else next();
});

export default router;
