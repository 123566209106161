const myPlugin = {
  install(app) {
    app.config.globalProperties.$hidePhone = (number) => {
      return number?.substring(0, 4) + 'xxxxxx' + number?.substring(9 + 1);
    };
    app.config.globalProperties.$name = (name) => {
      return name?.split(' ')[0];
    };
    app.config.globalProperties.$price = (price) => {
      return `Rp${parseInt(price).toLocaleString()}`;
    };
    app.config.globalProperties.$image = (image) => {
      return image
        ? `${process.env.VUE_APP_URL}${image}`
        : 'img/default-profile.png';
    };
  },
};

export default myPlugin;
