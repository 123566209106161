import { createApp } from 'vue';
import { createPinia } from 'pinia';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import './assets/css/index.css';
import './assets/scss/style.scss';
import axios from 'axios';
import helpers from './helpers';
import moment from 'moment';
import 'moment/locale/id'; // without this line it didn't work
import { setHeaderToken } from './includes/auth';
import { useAuthStore } from './store/authentication';
import firebase from './includes/firebase';

moment.locale('id');

axios.defaults.baseURL = process.env.VUE_APP_API_KEY;

const token = localStorage.getItem('token');
if (token) {
  setHeaderToken(token);
}

const app = createApp(App);
const pinia = createPinia();

app.use(pinia);
app.use(helpers);
const { getUser } = useAuthStore();
getUser();
app.use(router);
app.mount('#app');
