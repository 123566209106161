<template>
  <Form @submit="onSubmit">
    <div>
      <img src="/img/login-art.png" class="w-full h-56 rounded-[20px] object-cover mb-7 md:hidden" alt="Login Art" />
      <h1 class="font-nunito text-2xl font-bold">Selamat Datang 👋</h1>
      <p class="text-g90 leading-[150%] font-nunito mt-2 mb-8">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt...
      </p>
      <div class="flex gap-3 py-1 items-center px-4 border hover:border-g90 hover:cursor-pointer rounded mb-3">
        <span class="border-r pr-4 py-2">+62</span>
        <Field
          required
          v-model="form.whatsapp"
          name="whatsapp"
          type="phone"
          class="d-block py-2 w-full border-none outline-none hover:outline-none focus:outline-none bg-transparent group font-nunito hover:cursor-pointer"
          placeholder="Nomor Telepon" />
      </div>
      <div class="flex gap-3 items-center py-1 px-4 border hover:border-g90 hover:cursor-pointer rounded mb-3">
        <Field
          required
          name="password"
          :type="showPassword ? 'text' : 'password'"
          v-model="form.password"
          class="d-block py-2 px-0 input_custom w-full border-none outline-none hover:outline-none focus:outline-none bg-transparent group font-nunito hover:cursor-pointer"
          placeholder="Password" />
        <div @click="toggleShowPassword" class="cursor-pointer">
          <img v-if="showPassword" src="/img/icons/eye-open-icon.svg" alt="Hide Password" class="w-6 h-6" />
          <img v-else src="/img/icons/eye-closed-icon.svg" alt="Show Password" class="w-6 h-6" />
        </div>
      </div>
      <!-- <div class="flex items-center justify-between">
                <div class="flex items-start">
                    <div class="flex items-center h-5">
                        <input id="remember" aria-describedby="remember" type="checkbox"
                            class="w-4 h-4 border border-gray-300 rounded-md bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800"
                            required="">
                    </div>
                    <div class="ml-3 text-sm">
                        <label for="remember" class="text-gray-black">Remember me</label>
                    </div>
                </div>
                <a href="#" class="text-sm font-medium text-primary-600 hover:underline dark:text-primary-500">Lupa
                    Password?</a>
            </div> -->
      <div v-if="errorMessage" class="text-red text-sm leading-[150%] font-nunito mb-5">
        {{ errorMessage }}
      </div>
    </div>
    <div id="recaptcha-container"></div>
    <button
      type="submit"
      class="w-full text-white py-3 font-nunito font-bold rounded-xl mb-3 md:mb-0 mt-8"
      :class="loading ? 'bg-blue-400' : 'bg-blue-600'"
      :disabled="loading">
      <ButtonLoader v-if="loading" />
      <span v-else> Masuk </span>
    </button>
    <div class="flex justify-end">
      <p class="text-sm font-light text-black mt-3">
        Masuk dengan
        <router-link to="/login-number" class="font-medium text-primary-600 hover:underline dark:text-primary-500">
          No. Handphone
        </router-link>
      </p>
    </div>
  </Form>
</template>

<script setup>
import { Form, Field } from "vee-validate";
import { ref } from "vue";
import ButtonLoader from "@/components/ButtonLoader.vue";
import { useRouter } from "vue-router";
import axios from "axios";
import { useAuthStore } from "@/store/authentication";
import Swal from "sweetalert2";

const auth = useAuthStore();
const router = useRouter();
const password = ref("");
const showPassword = ref(false);
const errorMessage = ref();
const loading = ref(false);
const form = ref({
  whatsapp: "",
  password: "",
});

const toggleShowPassword = () => {
  showPassword.value = !showPassword.value;
};

const onSubmit = async () => {
  const originalWhatsapp = "+62" + form.value.whatsapp;
  loading.value = true;
  try {
    await auth.loginPassword({ ...form.value, whatsapp: originalWhatsapp });
    auth.getUser();
    loading.value = false;
    router.push("/beranda");
  } catch (error) {
    console.log(error);
    Swal.fire({
      title: "Gagal!",
      text: "No. hp atau password salah",
      icon: "error",
    });
    loading.value = false;
  }
};
</script>
