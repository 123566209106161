<template>
  <div v-if="page === 'login'" class="container">
    <div class="flex justify-center min-h-screen md:items-center">
      <div class="md:flex justify-between md:shadow-centerHard rounded-xl w-[57rem] overflow-hidden">
        <div class="md:py-16 md:ps-16 md:pr-24">
          <div class="py-12 flex flex-col justify-between h-screen md:h-full md:block">
            <Form @submit="phoneSendAuth">
              <div>
                <img
                  src="/img/login-art.png"
                  class="w-full h-56 rounded-[20px] object-cover mb-7 md:hidden"
                  alt="Login Art" />
                <h1 class="font-nunito text-2xl font-bold">Selamat Datang 👋</h1>
                <p class="text-g90 leading-[150%] font-nunito mt-2 mb-8">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt...
                </p>
                <div
                  class="flex gap-3 py-1 items-center px-4 border hover:border-g90 hover:cursor-pointer rounded mb-3">
                  <span class="border-r pr-4 py-2">+62</span>
                  <Field
                    required
                    v-model="login.whatsapp"
                    name="whatsapp"
                    type="phone"
                    class="d-block py-2 w-full border-none outline-none hover:outline-none focus:outline-none bg-transparent group font-nunito hover:cursor-pointer"
                    placeholder="Nomor Telepon" />
                </div>
                <div v-if="errorMessage" class="text-red text-sm leading-[150%] font-nunito mb-5">
                  {{ errorMessage }}
                </div>
              </div>
              <div id="recaptcha-container"></div>
              <button
                type="submit"
                class="w-full text-white py-3 font-nunito font-bold rounded-xl mb-3 md:mb-0 mt-8"
                :class="loading ? 'bg-blue-400' : 'bg-blue-600'"
                :disabled="loading">
                <ButtonLoader v-if="loading" />
                <span v-else> Masuk </span>
              </button>
              <div class="flex justify-end">
                <p class="text-sm font-light text-black mt-3">
                  Masuk dengan
                  <router-link to="/" class="font-medium text-primary-600 hover:underline dark:text-primary-500">
                    Password
                  </router-link>
                </p>
              </div>
            </Form>
          </div>
        </div>
        <div class="md:w-[35rem] hidden md:block">
          <img src="/img/login-art.png" class="w-full h-full object-cover" alt="Login Art" />
        </div>
      </div>
    </div>
  </div>
  <div v-else class="container">
    <div class="flex justify-center min-h-screen items-center">
      <div class="flex justify-between rounded-xl w-full md:w-[57rem] md:overflow-hidden md:shadow-centerHard">
        <div class="p-3 flex flex-col items-center md:py-8 md:ps-16 md:pr-24">
          <div class="w-[6rem] mb-12 md:mb-4">
            <img src="/img/verifikasi-image.svg" class="w-full h-full object-cover" alt="Verifikasi" />
          </div>
          <h1 class="font-nunito text-2xl font-bold">Verfikasi OTP</h1>
          <p class="text-g90 text-sm leading-[150%] font-nunito mt-2 mb-4 text-center w-11/12">
            Masukkan 6 digit kode yang telah dikirimkan melalui SMS ke nomor
            {{ $hidePhone(login.whatsapp) }}
          </p>
          <div class="max-w-2xl mx-auto mb-8 flex flex-col items-center">
            <Otpfield :digit-count="6" @sendCode="getCode" />
            <!-- <Otpfield :length="6" @entered="(v) => (otpValue = v)" /> -->
            <!-- <p class="text-g90 text-sm leading-[150%] font-nunito mt-4">
                  Kirim ulang kode?
                  <span class="text-primary"> {{ formatTime(timeLeft) }}</span>
                </p> -->
            <!-- <div class="mt-4" v-else>
                  <div id="recaptcha-container"></div>
                  <p
                    @click="resendCode"
                    class="text-primary text-sm leading-[150%] cursor-pointer font-nunito mt-4"
                  >
                    Kirim ulang kode?
                  </p>
                </div> -->
          </div>
          <button
            @click="onSubmit"
            class="w-full bg-primary text-white py-3 font-nunito font-bold rounded-xl"
            :class="loading ? 'bg-blue-400' : 'bg-blue-600'"
            :disabled="loading">
            <ButtonLoader v-if="loading" />
            <span v-else> Verifikasi</span>
          </button>
        </div>
        <div class="md:w-[35rem] hidden md:block">
          <img src="/img/login-art.png" class="w-full h-full object-cover" alt="Login Art" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { Form, Field } from "vee-validate";
import Swal from "sweetalert2";
import { ref, onMounted, defineAsyncComponent, computed } from "vue";
import { useRouter } from "vue-router";
import { useAuthStore } from "@/store/authentication";
import firebase from "firebase";
import ButtonLoader from "@/components/ButtonLoader.vue";
import Otpfield from "./Otpfield.vue";

const otpValue = ref("");
const loading = ref(false);
const page = ref("login");
const auth = useAuthStore();
const router = useRouter();
const errorMessage = ref();
const appVerifier = ref();
const login = ref({
  whatsapp: "",
});

// timeout for login
const timeLeft = ref(5);

const formatTime = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${String(minutes).padStart(2, "0")}:${String(remainingSeconds).padStart(2, "0")}`;
};

// start timeout
const startTimeout = () => {
  const timer = setInterval(() => {
    if (timeLeft.value > 0) {
      timeLeft.value--;
    } else {
      clearInterval(timer);
    }
  }, 1000);
};

// reset timeout
const resetTimeout = () => {
  clearInterval(timer);
  timeLeft.value = 5;
  startTimeout();
};

const getCode = (value) => {
  otpValue.value = value;
};

// submit login form
const onSubmit = async () => {
  loading.value = true;
  const originalWhatsapp = "+62" + login.value.whatsapp;

  try {
    await window.confirmationResult.confirm(otpValue.value);
    auth.setPhone(originalWhatsapp);

    const timeoutPromise = new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, 180000);
    });

    await Promise.race([timeoutPromise, auth.login({ whatsapp: originalWhatsapp })]);

    auth.getUser();
    router.push("/beranda");
    loading.value = false;
  } catch (error) {
    errorMessage.value = error?.response?.data?.message;
    loading.value = false;

    Swal.fire({
      title: "Gagal!",
      text: "Kode yang Anda masukkan salah",
      icon: "error",
    });
  }
};
const phoneSendAuth = async () => {
  loading.value = true;
  const originalWhatsapp = "+62" + login.value.whatsapp;

  try {
    await auth.login({ whatsapp: originalWhatsapp });

    var appVerifier = window.recaptchaVerifier;
    const confirmationResult = await firebase.auth().signInWithPhoneNumber(originalWhatsapp, appVerifier);

    window.confirmationResult = confirmationResult;
    page.value = "verifikasi";
    loading.value = false;
    startTimeout();
  } catch (error) {
    console.log(error);

    const errorMessageText = error.response ? error.response.data.message : error.message;

    Swal.fire({
      title: "Gagal!",
      text: errorMessageText,
      icon: "error",
    });

    loading.value = false;
  }
};

const render = () => {
  window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier("recaptcha-container");
  recaptchaVerifier.render();
};

onMounted(() => {
  render();
});
</script>
