import firebase from 'firebase';

var firebaseConfig = {
  apiKey: 'AIzaSyASZZDm6yLAZMwcLvX9QblnifTXtTy0J4Y',
  authDomain: 'rtku-57612.firebaseapp.com',
  projectId: 'rtku-57612',
  storageBucket: 'rtku-57612.appspot.com',
  messagingSenderId: '330600766510',
  appId: '1:330600766510:web:8f9b1779e8f763b48413bf',
  measurementId: 'G-WMYLEPB12M',
};

firebase.initializeApp(firebaseConfig);
