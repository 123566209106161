<template>
  <div>
    <span
      class="loader w-5 h-5 border-4 border-white border-b-transparent rounded-[50%] inline-block box-content"></span>
  </div>
</template>

<style scoped>
.loader {
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
